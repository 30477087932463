import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

export default function HomePage() {
  useEffect(() => {
    document.title = 'Lateral Thinking AI - Unauthorized';
  }, []);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="MainDiv">
      <Header />
      <section>
        <h1>Unauthorized</h1>
        <br />
        <p>You do not have access to the requested page.</p>
        <div className="flexGrow">
          <button onClick={goBack}>Go Back</button>
        </div>
      </section>
      <Footer />
    </div>
  );
}
