import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Alert from 'react-bootstrap/Alert';
import ConfirmDelete from './confirmDelete';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import EditableDiv from './editableDiv';
//import EditableDiv from 'react-wysiwyg-editor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const WidgetModal = (props) => {
  const widgetId = props.widgetId;
  const heading = props.heading;
  const widgetToolkit = props.widgetToolkit;
  const errRef = useRef();

  const axiosPrivate = useAxiosPrivate();
  const showWidget = props.showWidget;
  const [validated, setValidated] = useState(false);
  const [widgetName, setWidgetName] = useState('');
  const [widgetPrompt, setWidgetPrompt] = useState('');
  const [widgetSubmit, setWidgetSubmit] = useState('');
  const [widgetInputs, setWidgetInputs] = useState([]);
  const [widgetDescription, setWidgetDescription] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [landingText, setLandingText] = useState('');
  const [youtube, setYoutube] = useState('');
  const [image, setImage] = useState('');
  const [pageNumber, setPageNumber] = useState();

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);


  useEffect(() => {

    const saveData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //console.log("saveData",saveData);
    setLandingText(saveData);
  }, [editorState]);

  useEffect(() => {
    async function getWidget() {
      const controller = new AbortController();
      
      try {
        const response = await axiosPrivate.get(
          '/widgets/' + props.widgetType + '/' + widgetId,
          {
            signal: controller.signal,
          },
        );
        setWidgetName(response.data.name);
        setWidgetPrompt(response.data.prompt);
        setWidgetSubmit(response.data.submit);
        setWidgetInputs(response.data.inputs);
        setWidgetDescription(response.data.description);
        setLandingText(response.data.landingText);
        setPageNumber(response.data.pageNumber);
        setYoutube(response.data.youtube);
        setImage(response.data.image);
        // const _contentState = ContentState.createFromText(response.data.landingText);
        // const raw = convertToRaw(_contentState);  // RawDraftContentState JSON
        // setLandingText(raw);
        // console.log("raw",raw);
        const contentBlock = htmlToDraft(response.data.landingText);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);

        // console.log("editorState",editorState);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (props.widgetType && widgetId) {
      getWidget();
    } else {
      setValidated(false);
      setWidgetName('');
      setWidgetPrompt('');
      setWidgetSubmit('');
      setWidgetDescription('');
      setLandingText('');
      setWidgetInputs([]);
      setPageNumber();
      setYoutube('');
      setImage('');
      setEditorState( () => EditorState.createEmpty());
    }
  }, [axiosPrivate, props.widgetType, widgetId]);

  const handleSubmit = async () => {
    if (widgetName) {
      let payload = JSON.stringify({
        widgetid: widgetId,
        prompt: widgetPrompt,
        name: widgetName,
        submit: widgetSubmit,
        inputs: widgetInputs,
        toolkit: widgetToolkit,
        description: widgetDescription,
        landingText: landingText,
        pageNumber,
        youtube:youtube||'',
        image:image||'',
      });

      let url = '';
      if (heading === 'Add New Widget') {
        url = '/widgets/' + props.widgetType + '/add/';
      } else {
        url = '/widgets/' + props.widgetType + '/edit/';
      }
      try {
        await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        formatError(err);
      }
      props.setUpdate(); //forces the client list to refresh
    }

    setValidated(true);
  };

  function formatError(err) {
    if (!err?.response) {
      console.error('No Server Response');
      setErrMsg('No Server Response');
    } else if (err.response?.status === 409) {
      console.error('Client Exists');
      setErrMsg('Widget Exists');
    } else {
      setErrMsg('Save Failed');
      console.error('Save Failed');
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleCloseModal = (e) => {
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setShowConfirm(true);
    setValidated(false);
    props.setShowWidget(false);
  };

  const deleteWidget = async (e) => {
    e.preventDefault();
    setShowConfirm(false);
    await axiosPrivate.delete('/widgets/' + props.widgetType, {
      data: { id: widgetId },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    props.handleClose();
  };

  const handleCloseDelete = () => {
    setShowConfirm(false);
    props.setShowWidget(true);
  };



  return (
    <>
      <ConfirmDelete
        showConfirm={showConfirm}
        handleCloseDelete={handleCloseDelete}
        widgetName={widgetName}
        deleteWidget={deleteWidget}
      />

      <Modal show={showWidget} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Alert variant="success">
              <Alert.Heading>Success!</Alert.Heading>
              Widget has been saved.
            </Alert>
          ) : (
            <p></p>
          )}
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <Form noValidate validated={validated}>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formID">
                    <Form.Label>Widget ID:&nbsp;</Form.Label>
                    {widgetId}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3 pe-3" controlId="formPage">
                    <Form.Label>Page Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter number"
                      value={pageNumber || ''}
                      onChange={(e) =>
                        !/[0-9]/.test(e.target.value)
                          ? e.preventDefault
                          : setPageNumber(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formSubmit">
                    <Form.Label>Submit Button Text</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter text"
                      value={widgetSubmit || ''}
                      onChange={(e) => setWidgetSubmit(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3 pe-3" controlId="formName">
                    <Form.Label>Widget Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter name"
                      value={widgetName || ''}
                      onChange={(e) => setWidgetName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formImage">
                    <Form.Label>Widget Image URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter image url"
                      value={image || ''}
                      onChange={(e) => setImage(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                <Form.Group className="mb-3" controlId="formYoutube">
                  <Form.Label>Youtube Video ID</Form.Label>
                  <Form.Control
                    type="text"
                    rows={5}
                    placeholder="Eg: izt1HH_gSao"
                    value={youtube || ''}
                    onChange={(e) => setYoutube(e.target.value)}
                  />
                </Form.Group>
                </Col>
              </Row>
            </Container>

            <Form.Group className="mb-3" controlId="formLandingText">
              <Form.Label>Landing Page Text</Form.Label>




              <Editor 
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName="toolbar-class"
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                  inline: {options:['bold', 'italic', 'underline', 'strikethrough', 'monospace']},
                  list: {options: ['unordered', 'ordered']}
                }}
              />
  
                <textarea style={{width:'100%',height:'100px',display:'none'}}
                disabled
                value={landingText}
                />
  
             
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Thumbnail Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                placeholder="Enter description text shown under name"
                value={widgetDescription || ''}
                onChange={(e) => setWidgetDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPrompt">
              <Form.Label>Prompt</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter prompt"
                rows={8}
                value={widgetPrompt || ''}
                onChange={(e) => setWidgetPrompt(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {heading === 'Add New Widget' ? (
            <span>
              <Button variant="secondary" onClick={handleCancel}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Add Widget
              </Button>
            </span>
          ) : (
            <span>
              <Button variant="secondary" onClick={handleCancel}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete Widget
              </Button>
            </span>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WidgetModal;
