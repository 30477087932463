import React, { useEffect } from 'react';
import Header from '../components/Header';
import Landing from '../components/Landing';

export default function LandingPage() {
  useEffect(() => {
    document.title = 'Lateral Thinking AI';
  }, []);

  return (
    <div className="MainDiv">
      <Header />
      <Landing />
    </div>
  );
}
