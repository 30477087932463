import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { AuthProvider } from './context/AuthProvider';
import { ClientProvider } from './context/ClientProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { render } from "react-dom";                 // add this
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <AuthProvider>
        <ClientProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ClientProvider>
      </AuthProvider>
    </BrowserRouter>
);