import React, { useEffect } from "react";
import Header from "../components/Header";
import Home from "../components/Home";
import Footer from "../components/Footer";


export default function HomePage () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Home Page";
     }, []);


    return (
        
        <div className="MainDiv">
            <Header />
            <Home />
            <Footer />
        </div>
    
    );
}