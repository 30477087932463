import React, { useEffect} from "react";
import Header from "../components/Header";
import Sidebar from "../components/NavBar";
import Admin from "../components/Admin";
import Footer from "../components/Footer";
import {Container, Row, Col } from "react-bootstrap";

export default function HomePage () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Admin Page";
     }, []);


    return (
        
        <div>
        
            <Container fluid>
                <Row>
                    <Col><Header /></Col>
                </Row>
                <Row>
                    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>
                    <Col   xs sm md lg={true} id="page-content-wrapper" className="g-0">
                        <Admin />
                    </Col> 
                </Row>
                <Row>
                    <Col><Footer /></Col>
                </Row>
            </Container>

        </div>
    
    );
}