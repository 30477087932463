import React, {useEffect} from "react";
import Header from "../components/Header";
import Register from "../components/Register";
import Footer from "../components/Footer";



export default function RegisterPage () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Register Page";
     }, []);

    return (
        
        <div className="MainDiv">
            <Header />
            <Register />
            <Footer />
        </div>
    
    );
}