import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import WidgetModal from './widgetModal';
import ClientModal from './modifyClient';
import StyleModal from './modifyStyles';

const Clients = (props) => {
  const [clients, setClients] = useState();
  const [client, setClient] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const [showStyles, setShowStyle] = useState(false);
  const [key, setKey] = useState(0);

  const [clientId, setClientId] = useState('');
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    const controller = new AbortController();
    const getClients = async () => {
      if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get('/clients', {
            signal: controller.signal,
          });

          setClients(response.data);
          props.setAdd(false);
        } catch (err) {
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    getClients();
    renderAfterCalled.current = true;
  }, [axiosPrivate, navigate, location, key, props]);

  const handleEditClick = async (id, idx) => {
    setClientId(id);
    setShow(true);
    setClient(clients[idx]);
    //console.log('handleEditClick', show);
  };

  const handleWidgetClick = async (id, idx) => {
    setClientId(id);
    setShowWidget(true);
    setClient(clients[idx]);
    //console.log('handleWidgetClick', clients[idx]);
  };

  const handleStyleClick = async (id, idx) => {
    setClient(clients[idx]);
    setShowStyle(true);
    console.log('handleStyleClick', show);
  };

  const handleClose = () => {
    renderAfterCalled.current = false;
    setClientId();
    setShow(false);
    setShowWidget(false);
    setShowStyle(false);
    props.setAdd(false);
    setKey(key+1);
  };

  useEffect(() => {
    if(props.addClient){
      setClient();
      setClientId();
      setShow(true);
    }
  }, [props.addClient]);

  return (
    <div>
      <StyleModal showStyles={showStyles} setShowStyle={setShowStyle} handleClose={handleClose} clientObj={client} />
      <ClientModal clientId={clientId} show={show} setShow={setShow} handleClose={handleClose} clientObj={client} />
      <WidgetModal showWidget={showWidget} handleClose={handleClose} clientId={clientId} clientObj={client} />
      <Table responsive striped bordered hover className="admin-table" key={key}>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Domain</th>
            <th>Tagline</th>
            <th>GPT Model</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients?.length ? (
            clients.map((client, i) => (
              <tr key={i}>
                <td>{client?.clientname}</td>
                <td>{client?.domainurl}</td>
                <td>{client?.tagline}</td>
                <td>{client?.gptmodel}</td>
                <td className="controls text-nowrap">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleEditClick(client?.id,i)}
                  >
                    Details
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleWidgetClick(client?.id,i)}
                  >
                    Widgets
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleStyleClick(client?.id,i)}
                  >
                    Styles
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Clients;
