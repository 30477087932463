import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Layout from './components/Layout';
import Chatbot from './pages/Chat';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import User from './pages/Users';
import Widget from './pages/Widgets';
import Clients from './pages/Client';
import LandingPage from './pages/Landing';
import EmbedPage from './pages/Embed';
import BusinessName from './pages/generators/BusName';
import CourseName from './pages/generators/CourseName';
import Toolkit from './pages/Toolkits';
import CommunityName from './pages/generators/CommunityName';
import SocialPosts from './pages/generators/SocialPost';
import GroupTopics from './pages/generators/GroupTopic';
import CourseIdeas from './pages/generators/CourseIdea';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/NotFound';
import { Routes, Route } from 'react-router-dom';

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
};

function App() {
  return (
    <Routes>
      <Route path='*' element={<NotFound />}/>
      <Route path="/*" element={<Layout />} />
      {/* public routes */}
      
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="/" element={<Home />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="embed/:type/:id/:apikey" element={<EmbedPage />} />
 
      {/* we want to protect these routes */}
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="chat" element={<Chatbot />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="landing/:type/:id" element={<LandingPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="toolkits" element={<Toolkit />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="widgets" element={<Widget />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="users" element={<User />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="clients" element={<Clients />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="businessnames" element={<BusinessName />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="coursenames" element={<CourseName />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="communitynames" element={<CommunityName />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="socialposts" element={<SocialPosts />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="topics" element={<GroupTopics />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="courseideas" element={<CourseIdeas />} />
        </Route>
        
      </Route>
    </Routes>
  );
}

export default App;
