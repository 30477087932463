import axios from 'axios';
let BASE_URL = 'https://lateralthinkingai-node.azurewebsites.net';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  BASE_URL = 'http://localhost:3500';
} else {
  // production code
  BASE_URL = 'https://lateralthinkingai-node.azurewebsites.net';
}

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-type': 'application/json' },
  withCredentials: true,
});
