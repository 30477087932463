import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const CourseGen = () => {
  const [description, setDescription] = useState('');
  const [audience, setAudience] = useState('');
  const [responses, setItems] = useState('');
  const axiosPrivate = useAxiosPrivate();

  function onClick(val) {
    const index = val.i;
    for (let el of document.querySelectorAll('.clip-message')) {
      if (el.id === 'div' + index) {
        el.style.visibility = 'visible';
      } else {
        el.style.visibility = 'hidden';
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementsByClassName('info')[0].style.visibility = 'visible';
    for (let el of document.querySelectorAll('.clip-message')) {
      el.style.visibility = 'hidden';
    }

    if (description) {
      try {
        const response = await axiosPrivate.post(
          '/api/generate',
          JSON.stringify({
            prompt: description,
            audience: audience,
            type: 'communityname',
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );

        const res = response.data.result.split(/\r?\n/);
        setItems(
          res.map((response, i) => (
            <div className="result-div" key={i}>
              {response}
              <button
                className="bg-image"
                onClick={() => {
                  onClick({ i });
                  navigator.clipboard.writeText(response);
                }}
              ></button>
              <div className="clip-message" id={'div' + i}>
                Copied to clipboard
                <img
                  alt="check"
                  src="./assets/img/checkmark.png"
                  className="checkmark"
                ></img>
              </div>
            </div>
          )),
        );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="Generator container mt-5">
      <div className="row gx-5">
        <div className="col-sm-8 mb-2">
          <h1>AI-generated names for your community</h1>
          <br />
          <p>
            Don’t get caught up trying to find the perfect community name. Enter
            a short description of your community and this generator will take
            care of the rest.
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="description">Community Description *</label>
            <input
              type="text"
              id="description"
              autoComplete="off"
              placeholder="A community for people who build creative cat structures"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <br />
            <br />
            <label htmlFor="audience">Audience *</label>
            <input
              type="text"
              id="audience"
              autoComplete="off"
              placeholder="Cat owners, animal shelter volunteers"
              onChange={(e) => setAudience(e.target.value)}
              value={audience}
            />

            <div className="mt-5 button-div">
              <button
                className="btn btn-primary btn-lg"
                id="submitButton"
                type="submit"
              >
                Generate Names
              </button>
            </div>
          </form>
        </div>
        <div
          className="col-sm-4 showcase-img"
          style={{ backgroundImage: "url('assets/img/bg-showcase-1.jpg')" }}
        ></div>
      </div>
      <div className="row">
        <div className="info m-3 p-4">
          <p>
            Please be aware that the names generated may be registered or
            trademarked, and you are responsible for verifying the ability to
            use the names freely.
          </p>
          <h2>Here are some community name ideas for you</h2>
        </div>
      </div>
      <div className="row">
        <div className="results mt-4 mb-5">{responses}</div>
      </div>
    </div>
  );
};

export default CourseGen;
