import React, { useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/NavBar';
import User from '../components/Users';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';

export default function HomePage() {
  useEffect(() => {
    document.title = 'Lateral Thinking AI - User Management Page';
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col  xs sm md lg={true} id="page-content-wrapper">
            <User />
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
