import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

export default function HomePage() {
  useEffect(() => {
    document.title = 'Lateral Thinking AI - 404 Not Found';
  }, []);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="MainDiv">
      <Header />
      <section className="clear-section">
        <h1>404 - Page Not Found</h1>
        <br />
        <p>We cannot find the requested page.</p>
        <div className="flexGrow">
          <button onClick={goBack}>Go Back</button>
        </div>
      </section>
      <Footer />
    </div>
  );
}
