import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const Admin = () => {
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <h1>Admin Page</h1>
          <br />
          <h5>Current User details</h5>
          ClientId: {auth?.clientId}
          <br />
          Roles:{' '}
          {auth?.roles?.map((role, x) => (
            <span key={x}>
              {role}
              {x + 1 < auth?.roles.length ? ', ' : ''}
            </span>
          ))}
          <br />
          <span className="break-me">{auth?.accessToken}</span>
          <br />
          <br />
          <button
            onClick={() => {
              refresh();
            }}
          >
            Refresh Access Token
          </button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={12}>
          <h2>How to embed a widget</h2>
          <ol className="p-1">
            <li className="p-1 pb-5">
              Start by setting the AllowedOrigins for a client. This specifies
              which domains are allowed to use the api key for that client.<br/><br/>
              <Image src="./assets/AllowedOrigins.png" width="700"/>
            </li>
            <li className="p-1 pb-5">Copy the api_key and record it for step 4.<br/><br/><Image src="./assets/apikey.png" width="700"/>
            </li>
            <li className="p-1 pb-5">
              Navigate to the wigets page and click "Live Preview" button for
              the widget you want to embed. At the top of the popup, copy the{' '}
              {'<iframe></iframe>'} tag.
              <br/><br/><Image src="./assets/EmbedCapture.png" width="700"/>
            </li>
            <li className="p-1 pb-5">
              Replace the "API_KEY" variable in the url with the client apikey. Here is an example for the Course Topic widget with the lateralthinkingai.com apikey:<br />
              <blockquote className="mt-2">
                {
                  "<iframe src='/embed/CourseCreation/CourseTopic/3803ba9c-912b-4a6c-84a4-c8541a95c30c' title='ai-results'></iframe>"
                }
              </blockquote>
            </li>
            <li className="p-1">Copy this code into your client's CMS system, save and test.</li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
