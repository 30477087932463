import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import UserModal from './userModal';

const Users = (props) => {
  const [users, setUsers] = useState();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const renderAfterCalled = useRef(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const controller = new AbortController();

    const getUsers = async () => {
      if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get('/users', {
            signal: controller.signal,
          });
          //console.log(response.data);
          setUsers(response.data);
          props.setAdd(false);
        } catch (err) {
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    getUsers();
    renderAfterCalled.current = true;
  }, [axiosPrivate, navigate, location, key, props]);

  const handleEditClick = async (id, type) => {
    setUserId(id);
    setShow(true);
    console.log('handleEditClick', userId);
  };

  function isAdmin(role) {
    if (role) {
      return 'Admin';
    } else {
      return 'User';
    }
  }

  const setUpdate = () => {
    //renderAfterCalled.current = false;
    setKey(key + 1);
  };

  const handleClose = () => {
     props.setAdd(false);
     setUserId('');
     renderAfterCalled.current = false;
     setShow(false);
     setKey(key + 1);
     console.log("handleClose", userId); ///setUserId();
   };


   useEffect(() => {
    if(props.addUser){
      console.log("addUser", userId);
      setUserId('');
      setShow(true);
    }
  }, [props.addUser,userId]);


  return (
    <div>
      <UserModal
        show={show}
        setShow={setShow}
        user={userId}
        handleClose={handleClose}
        setUpdate={setUpdate}
      />
      <Table responsive striped bordered hover className="admin-table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Company</th>
            <th>Permission</th>
            <th>Location</th>
            <th>Last Login</th>
            <th></th>
          </tr>
        </thead>

        {users?.length ? (
          <tbody>
            {users.map((user, i) => (
              <tr key={i}>
                <td>{user?.username}</td>
                <td>
                  {user?.company}
                </td>
                <td className="td">{isAdmin(user?.roles?.Admin)}</td>

                <td className="td">
                  {user?.location !== '' ? (user?.location?.city +
                    ', ' +
                    user?.location?.region_code +
                    ', ' +
                    user?.location?.country_name):(<></>)}
                </td>
                <td className="td">{user?.lastlogin}</td>
                <td className="controls text-nowrap">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleEditClick(user)}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Loading ...</td>
            </tr>
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default Users;
