import { useState } from 'react';
import Clients from './clients/Clients';
import Button from 'react-bootstrap/Button';

const Client = () => {

  const [addClient, setAdd] = useState(false);
  const addNew = (e) => {
    setAdd(true);
  };

  return (
    <div className="mt-5">
        <h1>
          Client Management{' '}
          <Button variant="success" size="sm" onClick={() => addNew()}>
            Add New
          </Button>
        </h1>
        <br />
        <Clients addClient={addClient} setAdd={setAdd} />
    </div>
  );
};

export default Client;
