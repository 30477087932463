import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';

const LandingPage = () => {
  const { type, id } = useParams();
  const [showBack, setShowBack] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const APIKEY = '3803ba9c-912b-4a6c-84a4-c8541a95c30c'; //lateralthinking apikey
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.document.addEventListener('buttonEvent', handleEvent, false);
    function handleEvent(e) {
      //console.log(e.detail); // outputs: {foo: 'bar'}
      setShowBack(true);
    }
  }, []);

  function getLink() {
    if (showBack) {
      return (
        <Row className="hdn-btn bg-dark">
          <Col>
          
            <Button
              variant="primary"
              className="mt-3"
              onClick={() => goDashboard()}
            >
              Back To Dashboard
            </Button>

          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="hdn-btn bg-dark">
          <Col></Col>
        </Row>
      );
    }
  }
  const goDashboard = (e) => {
    navigate('/dashboard', { state: { from: location }, replace: true });
  };

  return (
    <Container fluid className="landing-bg">
      <Row>
        <Col>
          <div className="embed-frame">
            <iframe
              src={'/embed/' + type + '/' + id + '/' + APIKEY}
              title="ai-results"
            ></iframe>
          </div>
        </Col>
      </Row>
      {getLink()}
    </Container>
  );
};

export default LandingPage;
