
import Widgets from './widgets/Widgets';
const Widget = () => {
  return (
    <div className="mt-5">
        <h1>Widget Management</h1>
        <br />
        <Widgets />
        <br />
    </div>
  );
};

export default Widget;
