import useClient from '../hooks/useClient';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Image from 'react-bootstrap/Image';
//<Image src="https://lateralthinkingai.blob.core.windows.net/test/phil.png" />

const Home = () => {
  const { tagLine } = useClient();
  const navigate = useNavigate();

  function handleClick() {
    navigate('/login', { replace: true });
  }

  return (
    <div>
      <header className="masthead">
        <Container fluid className="position-relative">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h1 className="mb-5">{tagLine}</h1>
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => handleClick()}
                >
                  Try It Out &gt;&gt;
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <section className="features-icons bg-light text-center">
        <Container fluid>
          <Row>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-window m-auto text-primary"></i>
                </div>
                <h3>Business Name Generator</h3>
                <p className="lead mb-0">
                  This theme will look great on any device, no matter the size!
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-layers m-auto text-primary"></i>
                </div>
                <h3>Course Name Generator</h3>
                <p className="lead mb-0">
                  Featuring the latest build of the new Bootstrap 5 framework!
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-terminal m-auto text-primary"></i>
                </div>
                <h3>Community Name Generator</h3>
                <p className="lead mb-0">
                  Ready to use with your own content, or customize the source
                  files!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="features-icons bg-light text-center">
        <Container fluid>
          <Row>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-window m-auto text-primary"></i>
                </div>
                <h3>Social Posts Generator</h3>
                <p className="lead mb-0">
                  This theme will look great on any device, no matter the size!
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-layers m-auto text-primary"></i>
                </div>
                <h3>Group Topic Generator</h3>
                <p className="lead mb-0">
                  Featuring the latest build of the new Bootstrap 5 framework!
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                <div className="features-icons-icon d-flex">
                  <i className="bi-terminal m-auto text-primary"></i>
                </div>
                <h3>Course Idea Generator</h3>
                <p className="lead mb-0">
                  Ready to use with your own content, or customize the source
                  files!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="showcase">
        <Container fluid className="p-0">
          <Row className="g-0">
            <div
              className="col-lg-6 order-lg-2 text-white showcase-img"
              style={{ backgroundImage: "url('assets/img/bg-showcase-1.jpg')" }}
            ></div>
            <div className="col-lg-6 order-lg-1 my-auto showcase-text">
              <h2>Fully Responsive Design</h2>
              <p className="lead mb-0">
                When you use a theme created by Start Bootstrap, you know that
                the theme will look great on any device, whether it's a phone,
                tablet, or desktop the page will behave responsively!
              </p>
            </div>
          </Row>
          <Row className="g-0">
            <div
              className="col-lg-6 text-white showcase-img"
              style={{ backgroundImage: "url('assets/img/bg-showcase-2.jpg')" }}
            ></div>
            <div className="col-lg-6 my-auto showcase-text">
              <h2>Updated For Bootstrap 5</h2>
              <p className="lead mb-0">
                Newly improved, and full of great utility classNamees, Bootstrap
                5 is leading the way in mobile responsive web development! All
                of the themes on Start Bootstrap are now using Bootstrap 5!
              </p>
            </div>
          </Row>
          <Row className="g-0">
            <div
              className="col-lg-6 order-lg-2 text-white showcase-img"
              style={{ backgroundImage: "url('assets/img/bg-showcase-3.jpg')" }}
            ></div>
            <div className="col-lg-6 order-lg-1 my-auto showcase-text">
              <h2>Easy to Use & Customize</h2>
              <p className="lead mb-0">
                Landing Page is just HTML and CSS with a splash of SCSS for
                users who demand some deeper customization options. Out of the
                box, just add your content and images, and your new landing page
                will be ready to go!
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Home;
