import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';



const CourseGen = () => {
  const [description, setDescription] = useState('');
  const [audience, setAudience] = useState('');
  const [product, setProduct] = useState('Course');
  const [titles, setTitles] = useState('');
  const [responses, setItems] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n\n').map((item, index) => {
      //return (index === 0) ? item : [<br key={index} />, item];
      return item;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log('description', description);
    // console.log('audience', audience);
    // console.log('product', product);
    setItems(<p>Generating new results ...</p>);
    if (description) {
      try {
        const response = await axiosPrivate.post(
          '/api/generate',
          JSON.stringify({
            prompt: description,
            audience,
            product,
            titles,
            type: 'courseidea',
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );
        const res = escapedNewLineToLineBreakTag(response.data.result);
        const res2 = res.map((r,t)=> <p key={t}>{r.split(/(?=:)/).map((x,i,arr)=> i===0 && arr.length>1 ? <b key={i}>{x}</b> : x) } </p>);
        setItems(res2);
        //console.log("res2",res2);
      } catch (err) {
        console.error(err);
      }
    }
  };

  function change(event) {
    setProduct(event.target.value);
  }

  return (
    <div className="Generator container mt-5">
      <div className="row gx-5">
        <div className="col-sm-8 mb-2">
          <h1>Course Idea Generator</h1>
          <br />
          <p>
            If you’re a content creator, looking for sustainable, passive income, get started with Thinkific’s AI tool that will help you come up with course ideas.
          </p>
          <form onSubmit={handleSubmit}>
          <label htmlFor="description">What are you passionate or knowledgeable about? *</label>
            <input
              type="text"
              id="description"
              autoComplete="off"
              placeholder="Eg. Earning high scores on standardized tests"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <br />
            <br />
            <label htmlFor="audience">Tell me about your target audience (optional)</label>
            <input
              type="text"
              id="audience"
              autoComplete="off"
              placeholder="Eg. High school students who want to get into college"
              onChange={(e) => setAudience(e.target.value)}
              value={audience}
            />
            <br />
            <br />
            <label htmlFor="product">What type of content do you have?</label>
            <select
              aria-invalid="false"
              aria-label="What type of content do you have:"
              className="sc-5e33ae70-3 cnFpLa"
              onChange={change}
              value={product}
            >
              <option
                value="Course"
                className="sc-5e33ae70-4 homqZz"
              >
                Course
              </option>
              <option value="Blog Post" className="sc-5e33ae70-4 homqZz">
                Blog Post
              </option>
              <option
                value="Instructional Video"
                className="sc-5e33ae70-4 homqZz"
              >
                Instructional Video
              </option>
              <option value="Webinar" className="sc-5e33ae70-4 homqZz">
                Webinar
              </option>
              <option value="Workshop" className="sc-5e33ae70-4 homqZz">
                Workshop
              </option>
              <option value="Community" className="sc-5e33ae70-4 homqZz">
                Community
              </option>
              <option value="E-book" className="sc-5e33ae70-4 homqZz">
                E-book
              </option>
              <option value="Digital Download" className="sc-5e33ae70-4 homqZz">
                Digital Download
              </option>
              <option value="Masterclass" className="sc-5e33ae70-4 homqZz">
                Masterclass
              </option>
              <option value="Webinar" className="sc-5e33ae70-4 homqZz">
                Webinar
              </option>
              <option value="Other" className="sc-5e33ae70-4 homqZz">
                Other
              </option>
            </select>
            
            <label htmlFor="titles">What are some titles of your content? (optional)</label>
            <input
              type="text"
              id="titles"
              autoComplete="off"
              placeholder="Eg. High school students who want to get into college"
              onChange={(e) => setTitles(e.target.value)}
              value={titles}
            />

            <div className="mt-5 button-div">
              <button
                className="btn btn-primary btn-lg"
                id="submitButton"
                type="submit"
              >
                Generate Ideas
              </button>
            </div>
          </form>
        </div>
        <div
          className="col-sm-4 showcase-img"
          style={{ backgroundImage: "url('assets/img/bg-showcase-1.jpg')" }}
        ></div>
      </div>
      <div className="row mb-5">
        <div className="results mt-4 mb-5">{responses}</div>
        
      </div>
    </div>
  );
};

export default CourseGen;

