import Modal from 'react-bootstrap/Modal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef } from 'react';
import ConfirmDelete from './confirmDelete';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ToolkitModal = (props) => {
  const show = props.show;
  const toolkit = props.toolkit;
  const [showConfirm, setShowConfirm] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [collection, setCollection] = useState('');
  const [index, setIndex] = useState(1);
  const [heading, setHeading] = useState('Add New Toolkit');

  function removeSpecials(str) {
    return str.replace(/[^a-zA-Z ]/g, "");
  }

  useEffect(() => {
    if (toolkit) {
      console.log('useEffect', toolkit);
      setHeading('Update Toolkit: ' + toolkit.name);
      setName(toolkit.name);
      setCollection(toolkit.collection);
      setIndex(toolkit.index);
    } else {
      setHeading('Add New Toolkit');
      setName('');
      setCollection('');
      setIndex(1);
    }
  }, [toolkit]);

  const handleSubmit = async () => {
    if (name) {
      let payload = JSON.stringify({
        toolkitid: toolkit.id,
        collection: removeSpecials(collection),
        name,
        index
      });

      let url = '';
      if (!toolkit) {
        url = '/toolkits/add/';
      } else {
        url = '/toolkits/edit/';
      }

      try {
        await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        //console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          console.error('No Server Response');
          setErrMsg('No Server Response');
        } else if (err.response?.status === 409) {
          console.error('Client Exists');
          setErrMsg('User Exists');
        } else {
          setErrMsg('Save Failed, missing fields');
          console.error('Save Failed');
        }
      }
      props.setUpdate(); //forces the user list to refresh
    }

    setValidated(true);
  };

  const handleCloseModal = () => {
    console.log('handleCloseModal');
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleCancel = (e) => {
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleDelete = (e) => {
    setShowConfirm(true);
    setValidated(false);
    props.setShow(false);
  };

  const handleCloseDelete = () => {
    setShowConfirm(false);
    props.setShow(true);
  };

  const deleteToolkit = async () => {
    await axiosPrivate.delete('/toolkits', {
      data: { toolkitid: toolkit.id },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    setShowConfirm(false);
    props.handleClose();
  };

  return (
    <div>
      <ConfirmDelete
        showConfirm={showConfirm}
        handleClose={handleCloseDelete}
        name={name}
        deleteToolkit={deleteToolkit}
      />

      <Modal show={show} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Alert variant="success">
              <Alert.Heading>Success!</Alert.Heading>
              Toolkit has been saved.
            </Alert>
          ) : (
            <p></p>
          )}
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>

          <Form noValidate validated={validated}>
            <Container fluid>
              {toolkit ? (
                <Row>
                  <Col xs={12} className="pe-2">
                    <Form.Group className="mb-2" controlId="formID">
                      <Form.Label>toolkit ID:&nbsp;</Form.Label>
                      {toolkit?.id}
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <p></p>
              )}

              <Row>
                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formInddex">
                    <Form.Label>Index</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Index"
                      value={index || ''}
                      onChange={(e) => setIndex(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter name"
                      value={name || ''}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>

                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formCollection">
                    <Form.Label>Collection <small>*no spaces or special chars</small></Form.Label>
                    <Form.Control
                      readOnly={toolkit !== ''}
                      type="text"
                      value={collection || ''}
                      onChange={(e) => setCollection(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>


            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Close
          </Button>
          {heading === 'Add New Toolkit' ? (
            <>
              <Button variant="primary" onClick={handleSubmit}>
                Add Toolkit
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete Toolkit
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ToolkitModal;
