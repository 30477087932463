import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const GroupTopic = () => {
  const [description, setDescription] = useState('');
  const [product, setProduct] = useState('');
  const [responses, setItems] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const formatResponse = function (response) {
    return response === 'Closing paragraph:' ? '' : response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setItems(<p>Generating results ...</p>);
    if (description) {
      try {
        const response = await axiosPrivate.post(
          '/api/generate',
          JSON.stringify({
            prompt: description,
            product: product,
            type: 'grouptopic',
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        );

        const res = response.data.result.split(/\r?\n/);
        setItems(
          res.map((response, i) => <p key={i}>{formatResponse(response)}</p>),
        );
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="Generator container mt-5">
      <div className="row gx-5">
        <div className="col-sm-8 mb-2">
          <h1>Group Topic Discussions Generator</h1>
          <br />
          <p>
            Coming up with questions is easier with our AI tool. Get your
            learners engaged by asking relevant questions in your online
            community, group forum, and after each lesson.
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="description">What is your learning topic? *</label>
            <input
              type="text"
              id="description"
              autoComplete="off"
              placeholder="Eg: Taking timed tests"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <br />
            <br />
            <label htmlFor="product">Add more details (optional)</label>
            <input
              type="text"
              id="product"
              autoComplete="off"
              placeholder="Eg: I want my students product ideas for openai"
              onChange={(e) => setProduct(e.target.value)}
              value={product}
            />

            <div className="mt-5 button-div">
              <button
                className="btn btn-primary btn-lg"
                id="submitButton"
                type="submit"
              >
                Generate Questions
              </button>
            </div>
          </form>
        </div>
        <div
          className="col-sm-4 showcase-img"
          style={{ backgroundImage: "url('assets/img/bg-showcase-1.jpg')" }}
        ></div>
      </div>
      <div className="row">
        <div className="results mt-4 mb-5">{responses}</div>
      </div>
    </div>
  );
};

export default GroupTopic;
