import { React, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import useClient from '../hooks/useClient';
import { Link } from 'react-router-dom';
import axios from '../api/axios';

function Header() {
  const { clientName, setName, setTag, setApproved, setApprovedToolkits} = useClient();
  const renderAfterCalled = useRef(false);
  useEffect(() => {
    const getClients = async () => {
      if (!renderAfterCalled.current) {
        try {
          let domainURL = window.location.origin.replace(/\/+$/, "");
          domainURL = (domainURL === 'http://localhost:3000' ? 'https://lateralthinkingai.com' : domainURL);
          const response = await axios.post('/settings', {
            domainURL,
          });
          //console.log(response);
          setName(response.data.clientName);
          setTag(response.data.tagline);
          setApproved(response.data.approvedWidgets);
          setApprovedToolkits(response.data.approvedToolkits);
        } catch (err) {
          console.error(err);
        }
      }
    };

    getClients();
    renderAfterCalled.current = true;
  }, [setName, setTag, setApproved,setApprovedToolkits]);

  const userRoles = localStorage.getItem('roles');
  function getButtons() {
    if (!userRoles) {
      return (
        <div>
          <Link className="btn btn-primary login-button" to="/dashboard">
            Sign In
          </Link>
          <Link className="btn btn-secondary reg-button" to="/register">
            Sign Up
          </Link>
        </div>
      );
    } else {
      let showAdmin = '';
      if (userRoles?.includes('5150'))
        showAdmin = (
          <Link className="btn btn-primary login-button" to="/admin">
            Admin Page
          </Link>
        );

      return (
        <div>
          {showAdmin}
          <Link to="/dashboard" className="btn btn-primary login-button">
            Dashboard
          </Link>
          <Link className="btn btn-secondary reg-button" onClick={signOut}>
            Logout
          </Link>
        </div>
      );
    }
  }

  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/');
  };

  return (
    <div className="header-bar">
      <nav className="navbar navbar-light bg-light static-top">
        <div className="container">
          <Link className="navbar-brand" to="/">{clientName}</Link>
          {getButtons()}
        </div>
      </nav>
    </div>
  );
}

export default Header;
