import { createContext, useState } from 'react';

const ClientContext = createContext({});

export const ClientProvider = ({ children }) => {
  const [domain, setDomain] = useState('');
  const [tagLine, setTag] = useState('');
  const [clientName, setName] = useState('');
  const [approvedWidgets, setApproved] = useState([]);
  const [approvedToolkits, setApprovedToolkits] = useState([]);
  //console.log("ClientProvider:",clientName);
  return (
    <ClientContext.Provider
      value={{
        clientName,
        setName,
        domain,
        setDomain,
        tagLine,
        setTag,
        approvedWidgets,
        setApproved,
        approvedToolkits,
        setApprovedToolkits
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
