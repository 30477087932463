import React, { useEffect } from "react";
import Embed from "../components/Embed";

export default function EmbedPage () {

    useEffect(() => {
        document.title = "Lateral Thinking AI";
     }, []);


    return (
        
        <div className="MainDiv">

            <Embed />

        </div>
    
    );
}