import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useClient from '../hooks/useClient';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { approvedWidgets, approvedToolkits } = useClient([]);
  const renderAfterCalled = useRef(false);
  const [tabkey, setTabKey] = useState('CourseCreation');
  const [widgets, setWidgets] = useState();
  //const [widgetsM, setWidgetsM] = useState();
  const [toolkits, setToolkits] = useState();

  function compareByPage(a, b) {
    if (parseInt(a.data.pageNumber) < parseInt(b.data.pageNumber)) {
      return -1;
    }
    if (parseInt(a.data.pageNumber) > parseInt(b.data.pageNumber)) {
      return 1;
    }
    return 0;
  }

  function compareByIndex(a, b) {
    if (parseInt(a.index) < parseInt(b.index)) {
      return -1;
    }
    if (parseInt(a.index) > parseInt(b.index)) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getToolkits = async () => {
        try {
          const response = await axiosPrivate.get('/toolkits', {
            signal: controller.signal,
          });
          const intersection = response.data.sort(compareByIndex).filter(element => approvedToolkits.includes(element.collection));
          console.log("intersection:",intersection);
          console.log("approvedToolkits:",approvedToolkits);
          setToolkits(intersection);
        } catch (err) {
          console.error(err);
        }
    };
 
    getToolkits();
    
    
  }, [axiosPrivate,approvedToolkits]);


  useEffect(() => {
    const controller = new AbortController();
    const getWidgets = async () => {
      if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get(`/widgets`, {
            signal: controller.signal,
          });

          //console.log('response.data approvedWidgets2', approvedWidgets);
          //console.log('response.data widgets',response.data);
          const intersection = response.data.filter((element) =>
            approvedWidgets.includes(element.id),
          );

          setWidgets(intersection.sort(compareByPage));
           // console.log('response.data intersection',intersection);
          renderAfterCalled.current = true;
          
        } catch (err) {
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    //if (approvedWidgets.length > -1) getWidgets('CourseCreation');

    getWidgets();
  }, [axiosPrivate, navigate, location, approvedWidgets]);

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function getImage(img) {
    if(!img) {
      //console.log("getImage",img);
      const rndInt = randomIntFromInterval(1, 3);
      return 'assets/img/bg-showcase-' + rndInt + '.jpg';
    } else {
      return img;
    }  
  }


  // / approvedToolkits?.includes(object?.type)

  function getTabs(object, idx, kits, arr) {
    //console.log(kits.includes(object?.type));
    if (kits.includes(object?.collection)) {

      //let arr = (object?.type==='CourseCreation') ? w : w2;

      return (
        <Tab eventKey={object?.collection} title={object?.name} key={idx}>

     
          <Container fluid className="Dashboard">
            <Row>

              {arr?.map((widget, idx) => (

                widget?.type=== object?.collection ? (
                <Col md={3} key={widget.id} className="p-4">
                  <div className="content">
                    <div className="text-white showcase-img">
                      <Image src={getImage(widget.data.image)} rounded fluid />
                    </div>
                    <h1>{widget.data.name}</h1>
                    <p className="pb-5">{widget.data.description}</p>
                    <Link to={'/landing/' + widget.type + '/' + widget.id}>
                      {widget.data.submit} &gt;&gt;
                    </Link>
                  </div>
                </Col>
                 ) : (<></>)
              ))}
            </Row>
            <br clear="all" />
          </Container>
        </Tab>
      );
    }
  }

  return (
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      activeKey={tabkey}
      onSelect={(k) => setTabKey(k)}
      className="m-3"
    >
      {toolkits?.map((object, idx) => getTabs(object, idx, approvedToolkits,widgets))}
    </Tabs>
  );
};

export default Dashboard;
