import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Alert from 'react-bootstrap/Alert';
import CloseButton from 'react-bootstrap/CloseButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';

const WidgetModal = (props) => {
  const showWidget = props.showInput;
  const widgetId = props.widgetId;
  const heading = props.heading;
  const errRef = useRef();

  const axiosPrivate = useAxiosPrivate();
  const [validated, setValidated] = useState(false);
  const [widgetName, setWidgetName] = useState('');
  const [widgetPrompt, setWidgetPrompt] = useState('');
  const [widgetSubmit, setWidgetSubmit] = useState('');
  const [widgetInputs, setWidgetInputs] = useState([]);
  const [widgetDescription, setWidgetDescription] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [landingText, setLandingText] = useState('');
  const [youtube, setYoutube] = useState('');
  const [image, setImage] = useState('');
  
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);


  function compareByOrder(a, b) {
    if (parseInt(a.inputOrder) < parseInt(b.inputOrder)) {
      return -1;
    }
    if (parseInt(a.inputOrder) > parseInt(b.inputOrder)) {
      return 1;
    }
    return 0;
  }


  useEffect(() => {
    async function getWidget(widgetId) {
      const controller = new AbortController();
      try {
        const response = await axiosPrivate.get(
          '/widgets/' + props.widgetType + '/' + widgetId,
          {
            signal: controller.signal,
          },
        );
        const sorted = response.data.inputs.sort(compareByOrder);
        //console.log('addNewInput sorted', sorted);
        setWidgetName(response.data.name);
        setWidgetPrompt(response.data.prompt);
        setWidgetSubmit(response.data.submit);
        setWidgetInputs(sorted);
        setWidgetDescription(response.data.description);
        setLandingText(response.data.landingText);
        setPageNumber(response.data.pageNumber);
        setYoutube(response.data.youtube);
        setImage(response.data.image);
      } catch (err) {
        console.error(err);
      }
    }

    if (widgetId && heading !== 'Add New Widget' && showWidget) {
      getWidget(widgetId);
    }
  }, [axiosPrivate, props, widgetId,heading,showWidget]);

  const handleSubmit = async () => {
    let foundEmpty = false;
    widgetInputs.map(function (i) {
      if (
        i.name === '' ||
        i.label === '' ||
        i.placeholder === '' ||
        i.required === ''
      )
        foundEmpty = true;
      return null;
    });

    if (widgetName && !foundEmpty && widgetInputs.length > 0) {
      const payload = JSON.stringify({
        widgetid: widgetId,
        prompt: widgetPrompt,
        name: widgetName,
        submit: widgetSubmit,
        inputs: widgetInputs,
        youtube,
        image,
        description: widgetDescription || '',
        landingText: landingText || '',
        pageNumber
      });

      let url = '';
      if (heading === 'Add New Widget') {
        url = '/widgets/' + props.widgetType + '/add/';
      } else {
        url = '/widgets/' + props.widgetType + '/edit/';
      }
      try {
        await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        formatError(err);
      }
      props.setUpdate(); //forces the client list to refresh
    }

    setValidated(true);
  };

  function formatError(err) {
    if (!err?.response) {
      console.error('No Server Response');
      setErrMsg('No Server Response');
    } else if (err.response?.status === 409) {
      console.error('Client Exists');
      setErrMsg('Widget Exists');
    } else {
      setErrMsg('Save Failed');
      console.error('Save Failed');
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleCloseModal = (e) => {
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const updateField = (newVal, index, controlname) => {
    let inputs;
    if (newVal === '') setValidated(false);
    //newVal = newVal.toLowerCase().replace(/[^a-zA-Z]/g, "");
    // eslint-disable-next-line no-eval
    eval(
      `inputs = widgetInputs.map((input,i) => i===index?{...input,${controlname}:newVal}:input)`,
    );
    //console.log("updateField",inputs);
    setWidgetInputs(inputs);
  };

  const addNewInput = () => {
    if (widgetInputs[0]?.name || widgetInputs?.length === 0) {
      //prevent double add
      let newInputs = {
        name: '',
        type: 'text',
        label: '',
        placeholder: '',
        inputOrder: 1,
        required: false,
      };

      const newArr = [newInputs, ...widgetInputs];
      const sorted = newArr.sort(compareByOrder);
      console.log('addNewInput sorted', sorted);
      setWidgetInputs(sorted);
      var elem = document.getElementsByTagName('fieldset')[0];
      if (elem) elem.classList.add('active-field');
    }
  };

  const deleteInput = () => {
    setWidgetInputs(widgetInputs.slice(1));
    var elem = document.getElementsByTagName('fieldset')[0];
    if (elem) elem.classList.remove('active-field');
  };


  
  return (
    <Modal show={showWidget} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{heading} </Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-body">
        {widgetInputs?.length === 0 ? (
          <div className="no-inputs">
            <h5>No Inputs for {heading}</h5>
            <Button size="sm" variant="info" onClick={addNewInput}>
              Add new input
            </Button>
          </div>
        ) : (
          <>
            {success ? (
              <Alert variant="success">
                <Alert.Heading>Success!</Alert.Heading>
                Widget has been saved.
              </Alert>
            ) : (
              <p></p>
            )}
            <p
              ref={errRef}
              className={errMsg ? 'errmsg' : 'offscreen'}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <Form noValidate validated={validated}>
              {widgetInputs?.map((input, x) => (
                <fieldset className="mb-3" key={x}>
                  <CloseButton
                    className="delete-input"
                    onClick={deleteInput}
                  ></CloseButton>
                  <Form.Group className="mb-3" controlId="formPage">
                    <Form.Label>Order On Page</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter number"
                      value={input?.inputOrder || x}
                      onChange={(e) => (!/[0-9]/.test(e.target.value)) ? e.preventDefault : updateField(e.target.value, x, 'inputOrder')}
                    />
                  </Form.Group> 
                  <Form.Group className="mb-3" controlId={'formName' + x}>
                  <Form.Label>{input?.type.charAt(0).toUpperCase() + input?.type.slice(1)} Input Name</Form.Label>
                    <InputGroup className="mb-3">
                      <DropdownButton
                        variant="outline-secondary"
                        title="Input Type"
                        id={"input-group-dropdown" + x}
                       
                      >
                        <Dropdown.Item  onClick={() => updateField('text',x,'type')}>Text Input</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateField('selectarea',x,'type')}>Select Area</Dropdown.Item>
                      </DropdownButton>
                      
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter name"
                        value={input?.name || ''}
                        onChange={(e) => updateField(e.target.value, x, 'name')}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={'formLabel' + x}>
                    <Form.Label>Input Label</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter label"
                      value={input?.label || ''}
                      onChange={(e) => updateField(e.target.value, x, 'label')}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId={'formPlaceholder' + x}
                  >
                    <Form.Label>Placeholder</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter placeholder text"
                      value={input?.placeholder || ''}
                      onChange={(e) =>
                        updateField(e.target.value, x, 'placeholder')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId={'formRequired' + x}>
                    <Form.Label>Required</Form.Label>
                    {' '}<Form.Check // prettier-ignore
                      type="switch"
                      label=""
                      checked={input?.required}
                      onChange={(e) =>
                        updateField(!input?.required, x, 'required')
                      }
                    /><span>Require this input when submitting.</span>
                  </Form.Group>
                </fieldset>
              ))}
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="info" onClick={addNewInput}>
          Add new input
        </Button>
        <Button size="sm" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button size="sm" variant="success" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WidgetModal;
