import React, {useEffect} from "react";
import Header from "../components/Header";
import Chatbot from "../components/chatbot/chatbot";
import Footer from "../components/Footer";
import {Container, Row, Col } from "react-bootstrap";
import Sidebar from "../components/NavBar";

export default function ChatPage () {
    
    useEffect(() => {
        document.title = "Lateral Thinking AI - Lateral Bot";
     }, []);

    return (
        
        <div className="MainDiv">
            <Container fluid>
                <Row>
                    <Col><Header /></Col>
                </Row>
                <Row>
                    <Col md={2} id="sidebar-wrapper" className="bg-dark g-0">      
                      <Sidebar />
                    </Col>
                    <Col   xs sm md lg={true} id="page-content-wrapper" className="g-0">
                        <Chatbot />
                    </Col> 
                </Row>
                <Row>
                    <Col><Footer /></Col>
                </Row>
            </Container>
        </div>
    
    );
}