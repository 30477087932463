import { React } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
  return (
    <>
      <Nav
        className="col-md-12 d-none d-md-block bg-dark sidebar"
        activeKey="/dashboard"
        //onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
      >
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          <Link to="/admin">Admin</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/users">Users</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/clients">Clients</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/widgets">Widgets</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/toolkits">Toolkits</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/chat">Chatbot</Link>
        </Nav.Item>
      </Nav>
    </>
  );
};
export default Sidebar;