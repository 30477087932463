import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Alert from 'react-bootstrap/Alert';

const WidgetModal = (props) => {
  const showPreview = props.showPreview;
  const widgetId = props.widgetId;
  const widgetType = props.widgetType;
  const errRef = useRef();

  const axiosPrivate = useAxiosPrivate();
  const [validated, setValidated] = useState(false);
  const [widgetName, setWidgetName] = useState();
  const [widgetPrompt, setWidgetPrompt] = useState();
  const [widgetSubmit, setWidgetSubmit] = useState();
  const [widgetInputs, setWidgetInputs] = useState();
  const [userInputs, setUserInputs] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [originalPrompt, setOriginalPrompt] = useState();
  const [aiResponse, setAiResponse] = useState('');

  const iframePreview = `<iframe src='/embed/${widgetType}/${widgetId}/API_KEY' title='ai-results'></iframe>`;

  function compareByOrder(a, b) {
    if (parseInt(a.inputOrder) < parseInt(b.inputOrder)) {
      return -1;
    }
    if (parseInt(a.inputOrder) > parseInt(b.inputOrder)) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    async function getWidget() {
      const controller = new AbortController();
      try {
        const response = await axiosPrivate.get(
          '/widgets/' + props.widgetType + '/' + widgetId,
          {
            signal: controller.signal,
          },
        );

        const sorted = response.data.inputs.sort(compareByOrder);
        setWidgetName(response.data.name);
        setWidgetPrompt(response.data.prompt);
        setWidgetSubmit(response.data.submit);
        setWidgetInputs(sorted);
        setOriginalPrompt(response.data.prompt);
        //setWidgetType(response.type);
        //console.log('response', response);
      } catch (err) {
        console.error(err);
      }
    }
    if (props.widgetType && widgetId && showPreview) {
      getWidget();
    }
  }, [axiosPrivate, props, widgetId, showPreview]);

  const handleCancel = (e) => {
    e.preventDefault();
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    setUserInputs([]);
    props.handleClose();
  };

  const handleCloseModal = (e) => {
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    setUserInputs([]);
    props.handleClose();
  };

  const updateField = (newVal, index, controlname) => {
    let inputs;
    if (newVal === '') setValidated(false);
    var ui = widgetInputs.map((input) => ({ field: input.name, value: '' }));
    newVal = newVal.replace(/[^a-zA-Z ]/g, '');
    inputs = ui.map((input, i) =>
      i === index
        ? { field: input.field, value: newVal }
        : { field: input.field, value: userInputs[i]?.value || '' },
    );
    setUserInputs(inputs);
  };

  const handleSubmit = async () => {
    document.getElementsByClassName('info')[0].style.visibility = 'visible';
    var blnError = false;
    let prompt = originalPrompt;
    userInputs.forEach((input) => {
      const newVal = input.value.replace(/[^a-zA-Z ]/g, '');
      if (!input.value) blnError = true;
      // eslint-disable-next-line no-eval
      eval(
        `prompt = prompt.replaceAll('{${input.field}}','<b><u>${newVal}</u></b>');`,
      );
    });

    if (!blnError && userInputs.length > 0) {
      setWidgetPrompt(prompt);
      setErrMsg('');
      setSuccess(true);
      setAiResponse('sending ...');

      let payload = JSON.stringify({
        prompt,
      });

      let url =
        '/ai/' +
        widgetType +
        '/' +
        widgetId +
        '/3803ba9c-912b-4a6c-84a4-c8541a95c30c';

      try {
        const response = await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        console.log(response?.data.result);
        setSuccess(true);
        setAiResponse(response?.data.result);
      } catch (err) {
        if (!err?.response) {
          console.error('No Server Response');
          setErrMsg('No Server Response');
        } else if (err.response?.status === 409) {
          console.error('Client Exists');
          setErrMsg('Widget Exists');
        } else {
          setErrMsg('Save Failed');
          console.error('Save Failed');
        }
      }
    } else {
      setWidgetPrompt(originalPrompt);
      setSuccess(false);
      setErrMsg('Missing form values.');
    }
    setValidated(true);
  };

  return (
    <Modal show={showPreview} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{widgetName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">
            <Alert.Heading>Success!</Alert.Heading>
            See AI response below.
          </Alert>
        ) : (
          <p></p>
        )}
        <p
          ref={errRef}
          className={errMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
        >
          {errMsg}
        </p>

        <Form noValidate validated={validated}>
          <Form.Label>IFrame Code</Form.Label>
          <div className="iframe-preview">{iframePreview}</div>

          {widgetInputs?.map((input, x) => (
            <Form.Group
              className="mb-3"
              controlId={'form' + input.name}
              key={x}
            >
              <Form.Label>{input.label}</Form.Label>
              {input.type==="text" ? (
                <Form.Control
                  required
                  type="text"
                  placeholder={input.placeholder}
                  onChange={(e) => updateField(e.target.value, x, input.name)}
                />
                ) : input.type==="selectarea" ? (
                  <Form.Control
                    required
                    as="textarea" rows={4}
                    placeholder={input.placeholder}
                    onChange={(e) => updateField(e.target.value, x, input.name)}
                  />
                ) : (<p>{input.type}</p>)}
            </Form.Group>
          ))}

          <Button variant="primary" className="mt-3" onClick={handleSubmit}>
            {widgetSubmit}
          </Button>

          <fieldset className="prompt-preview">
            <legend>Generated Prompt</legend>
            <div dangerouslySetInnerHTML={{ __html: widgetPrompt }} />
          </fieldset>

          <fieldset className="prompt-response info">
            <legend>AI Response</legend>
            {aiResponse}
          </fieldset>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WidgetModal;
