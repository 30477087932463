import React, { useEffect} from "react";
import Header from "../../components/Header.js";
import SocialPost from "../../components/generators/socialpost.js";
import Footer from "../../components/Footer.js";


export default function SocialPosts () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Social Media Post Generator";
     }, []);


    return (
        
        <div className="MainDiv">
            <Header />
            <SocialPost />
            <Footer />
        </div>
    
    );
}