import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Alert from 'react-bootstrap/Alert';

const ModifyStyles = (props) => {
  const clientObj = props.clientObj;
  const [background, setBackground] = useState('');
  const [color, setColor] = useState('');
  const [font, setFont] = useState('');
  const [secondarycolor, setSecondary] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setErrMsg('');
    setSuccess(false);

    if (clientObj) {
      setBackground(clientObj?.styles?.background || '');
      setColor(clientObj?.styles?.color || '');
      setFont(clientObj?.styles?.font || '');
      setSecondary(clientObj?.styles?.secondarycolor || '');
    }
  }, [clientObj]);

  const handleSubmit = async () => {
    if (clientObj) {
      const payload = JSON.stringify({
        clientid: clientObj.id,
        clientName: clientObj.clientname,
        domainURL: clientObj.domainurl,
        tagline: clientObj.tagline,
        apikey: clientObj.apikey,
        gptModel: clientObj.gptmodel,
        gptTemp: clientObj.gpttemp,
        approvedToolkits: clientObj.approvedToolkits,
        allowedOrigins: clientObj.allowedOrigins,
        styles: {
          background: background,
          color: color,
          font: font,
          secondarycolor: secondarycolor,
        },
      });
      console.log('handleSubmit', payload);
      console.log('handleSubmit', clientObj);

      try {
        await axiosPrivate.post('/clients/edit/', payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          console.error('No Server Response');
          setErrMsg('No Server Response');
        } else {
          setErrMsg('Save Failed, missing fields');
          console.error('Save Failed', err);
        }
      }

      setValidated(true);
    }
  };

  return (
    <Modal show={props.showStyles} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modify Styles For {clientObj?.clientname}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">
            <Alert.Heading>Success!</Alert.Heading>
            Client has been saved.
          </Alert>
        ) : (
          <p></p>
        )}
        <p
          ref={errRef}
          className={errMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Form noValidate validated={validated}>
          <Container fluid>
            <Row className="mb-3">
              <Col xs={4} className="pe-2">
                <Form.Label>Background Color</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter background color"
                  value={background || ''}
                  onChange={(e) => setBackground(e.target.value)}
                />
              </Col>
              <Col xs={2} className="pe-2 ">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control
                  type="color"
                  id="backgroundColor"
                  defaultValue="#563d7c"
                  title="Choose your color"
                  value={background || ''}
                  onChange={(e) => setBackground(e.target.value)}
                />
              </Col>
              <Col xs={4} className="pe-2 ">
                <Form.Label>Primary Color</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Primary Color"
                  value={color || ''}
                  onChange={(e) => setColor(e.target.value)}
                />
              </Col>
              <Col xs={1} className="pe-2 ">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control
                  type="color"
                  id="primaryColor"
                  defaultValue="#563d7c"
                  title="Choose your color"
                  value={color || ''}
                  onChange={(e) => setColor(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={4} className="pe-2">
                <Form.Label>Secondary Color</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter background color"
                  value={secondarycolor || ''}
                  onChange={(e) => setSecondary(e.target.value)}
                />
              </Col>
              <Col xs={2} className="pe-2 ">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control
                  type="color"
                  id="secondaryColor"
                  defaultValue="#563d7c"
                  title="Choose your color"
                  value={secondarycolor || ''}
                  onChange={(e) => setSecondary(e.target.value)}
                />
              </Col>
              <Col xs={6} className="pe-2 ">
                <Form.Label>Font</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Font"
                  value={font || ''}
                  onChange={(e) => setFont(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    backgroundColor: background,
                    color: color,
                    fontFamily: font,
                    padding: '25px',
                  }}
                >
                  <h3>This is the primary color</h3>
                  <p style={{ color: secondarycolor }}>
                    This is the secondary color
                  </p>
                  <p>
                    <b>This is also the primary color</b>
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    id="example"
                    defaultValue="Example text Input"
                    title="Example Input"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModifyStyles;
