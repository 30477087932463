import React, { useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/NavBar';
import Widgets from '../components/Widgets';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';

export default function WidgetPage() {
  useEffect(() => {
    document.title = 'Lateral Thinking AI - Widget Management Page';
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row>
          <Col id="sidebar-wrapper" xs="2">
            <Sidebar />
          </Col>
          <Col xs sm md lg={true} id="page-content-wrapper">
            <Widgets />
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
