import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ToolkitModal from './toolkitModal';

const Toolkits = (props) => {
  const [toolkits, setToolkits] = useState();
  const [show, setShow] = useState(false);
  const [toolKit, seToolKit] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const renderAfterCalled = useRef(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const controller = new AbortController();

    const getToolkits = async () => {
      if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get('/toolkits', {
            signal: controller.signal,
          });
          //console.log(response.data);
          setToolkits(response.data);
          props.setAdd(false);
        } catch (err) {
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

    getToolkits();
    renderAfterCalled.current = true;
  }, [axiosPrivate, navigate, location, key, props]);

  const handleEditClick = async (payload, type) => {
    seToolKit(payload);
    setShow(true);
    //console.log('handleEditClick', toolKit);
  };

  const setUpdate = () => {
    //renderAfterCalled.current = false;
    setKey(key + 1);
  };

  const handleClose = () => {
    props.setAdd(false);
    seToolKit('');
    renderAfterCalled.current = false;
    setShow(false);
    setKey(key + 1);
    //console.log('handleClose', toolKit); ///setUserId();
  };

  useEffect(() => {
    if (props.addToolkit) {
      //console.log('addToolkit', toolKit);
      seToolKit('');
      setShow(true);
    }
  }, [props.addToolkit, toolKit]);

  return (
    <div>

      <ToolkitModal
        show={show}
        setShow={setShow}
        toolkit={toolKit}
        handleClose={handleClose}
        setUpdate={setUpdate}
      />


      <Table responsive striped bordered hover className="admin-table">
        <thead>
          <tr>
            <th>Index</th>
            <th>ID</th>
            <th>Toolkit Name</th>
            <th>Collection</th>
            <th></th>
          </tr>
        </thead>

        {toolkits?.length ? (
          <tbody>
            {toolkits.map((toolkit, i) => (
              <tr key={i}>
                <td>{toolkit?.index}</td>
                <td>{toolkit?.id}</td>
                <td>{toolkit?.name}</td>
                <td>{toolkit?.collection}</td>
                <td className="controls text-nowrap">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleEditClick(toolkit)}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Loading ...</td>
            </tr>
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default Toolkits;
