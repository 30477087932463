import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ConfirmDelete = (props) => {
    return (
        <Modal show={props.showConfirm} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {props.userName}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This cannot be undone, do you really want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={props.deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    );
};

export default ConfirmDelete;