import { useState } from 'react';
import Toolkits from './toolkits/Toolkits';
import Button from 'react-bootstrap/Button';


const Toolkit = () => {

  const [addToolkit, setAdd] = useState(false);
  const addNew = (e) => {
    setAdd(true);
    //console.log("User addToolkit:",addToolkit)
  };


  return (
    <div className="mt-5">
        <h1>Toolkit Management{' '}<Button variant="success" size="sm" onClick={() => addNew()}>
            Add New
          </Button></h1>
        <br />
        <Toolkits addToolkit={addToolkit} setAdd={setAdd} />
        <br />
    </div>
  );
};

export default Toolkit;
