import Modal from 'react-bootstrap/Modal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef } from 'react';
import ConfirmDelete from './confirmDelete';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const UserModal = (props) => {
  const show = props.show;
  const user = props.user;
  const [showConfirm, setShowConfirm] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [username, setUserName] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [clientId, setClientId] = useState('ogEpLU2xZnnYMuKqvYcC');
  const [blnAdmin, setBlnAdmin] = useState(false);

  const [heading, setHeading] = useState('Add New User');

  function isAdmin(role) {
    if (role) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (user) {
      console.log('useEffect', user);
      setHeading('Update User: ' + user.username);
      setUserId(user.id);
      setClientId(user.clientId);
      setUserName(user.username);
      setCompany(user.company);
      setPassword(user.password);
      setBlnAdmin(isAdmin(user?.roles?.Admin));
    } else {
      setHeading('Add New User');
      setUserId('');
      setClientId('ogEpLU2xZnnYMuKqvYcC');
      setUserName('');
      setCompany('');
      setPassword('');
      setBlnAdmin(false);
    }
  }, [user]);

  const handleSubmit = async () => {
    if (username) {
      let r;
      if (blnAdmin) {
        r = { User: 2001, Admin: 5150 };
      } else {
        r = { User: 2001 };
      }

      let payload = JSON.stringify({
        id: userId,
        clientId,
        username,
        company,
        password,
        roles: r,
      });

      let url = '';
      if (!user) {
        url = '/users/add/';
      } else {
        url = '/users/edit/';
      }

      try {
        await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          console.error('No Server Response');
          setErrMsg('No Server Response');
        } else if (err.response?.status === 409) {
          console.error('Client Exists');
          setErrMsg('User Exists');
        } else {
          setErrMsg('Save Failed, missing fields');
          console.error('Save Failed');
        }
      }
      props.setUpdate(); //forces the user list to refresh
    }

    setValidated(true);
  };

  const updateBlnAdmin = () => {
    setBlnAdmin(!blnAdmin);
    console.log('updateBlnAdmin', blnAdmin);
  };

  const handleCloseModal = () => {
    console.log('handleCloseModal');
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleCancel = (e) => {
    setSuccess(false);
    setErrMsg('');
    setValidated(false);
    props.handleClose();
  };

  const handleDelete = (e) => {
    setShowConfirm(true);
    setValidated(false);
    props.setShow(false);
  };

  const handleCloseDelete = () => {
    setShowConfirm(false);
    props.setShow(true);
  };

  const deleteUser = async () => {
    await axiosPrivate.delete('/users', {
      data: { id: user.id },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    setShowConfirm(false);
    props.handleClose();
  };

  return (
    <div>
      <ConfirmDelete
        showConfirm={showConfirm}
        handleClose={handleCloseDelete}
        userName={username}
        deleteUser={deleteUser}
      />

      <Modal show={show} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Alert variant="success">
              <Alert.Heading>Success!</Alert.Heading>
              User has been saved.
            </Alert>
          ) : (
            <p></p>
          )}
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>

          <Form noValidate validated={validated}>
            <Container fluid>
              {user ? (
                <Row>
                  <Col xs={12} className="pe-2">
                    <Form.Group className="mb-2" controlId="formID">
                      <Form.Label>User ID:&nbsp;</Form.Label>
                      {user?.id}
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <p></p>
              )}

              <Row>
                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter name"
                      value={username || ''}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                {!user ? (
                  <Col xs={6} className="pe-2">
                    <Form.Group className="mb-3" controlId="formPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter password"
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <p></p>
                )}
              </Row>
              <Row>
                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formCompany">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter company"
                      value={company || ''}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col xs={6} className="pe-2">
                  <Form.Group
                    className="mb-3 toolkits-row"
                    controlId="formClientTagline"
                  >
                    <Form.Label>Is Admin?</Form.Label>
                    <br className="clear" />
                    <Form.Check
                      type="switch"
                      checked={blnAdmin}
                      onChange={(e) => updateBlnAdmin(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Close
          </Button>
          {heading === 'Add New User' ? (
            <>
              <Button variant="primary" onClick={handleSubmit}>
                Add User
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete User
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserModal;
