import { useRef, useState, useEffect } from 'react';
import './styles.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import {jwtDecode} from "jwt-decode";
import { useNavigate, useLocation } from 'react-router-dom';

const MSG_URL = "/api";
const BOT_IMG = "https://img.icons8.com/?size=77&id=AiI2Vzcqt9Op&format=png";
const PERSON_IMG = "https://img.icons8.com/?size=77&id=23261&format=png";
//const PERSON_IMG2 = "https://img.icons8.com/?size=77&id=iBnwfeZ6ioYp&format=png";

const BOT_NAME = "LateralBot";


const Chatbot = () => {

    const axiosPrivate = useAxiosPrivate();
    const [msgs, setMsgs] = useState('');
    const [msg, setMsg] = useState();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const div = useRef(null);
    useEffect(()=> {
      //div?.current?.scrollIntoView();

      const lastChildElement = div.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    
    
    }, [msgs]);
 


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        async function fetchData() {
            //userRef.current.focus();
             try {
                const response = await axiosPrivate.get(MSG_URL, {
                    signal: controller.signal,
                });
                //console.log(response.data);
                isMounted && setMsgs(response.data);
                isMounted = true;
            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }
        fetchData();
        return () => {
            isMounted = false;
            
            //controller.abort();
        };
    }, [axiosPrivate,location,navigate]);


    function getMessage(msg, i) {

        const decoded = jwtDecode(auth?.accessToken);
        const personName = decoded?.UserInfo?.username;
        const name = msg?.role==="user" ? personName : BOT_NAME;
        const side = msg?.role==="user" ? "right" : "left";
        const img = msg.role==="user" ? PERSON_IMG : BOT_IMG;

        return (
        <div key={i} className={`msg ${side}-msg`}>
            <div className="msg-img" style={{ backgroundImage: `url(${img})` }}></div>
            <div className="msg-bubble">
            <div className="msg-info">
                <div className="msg-info-name">{name}</div>
                <div className="msg-info-time"></div>
            </div>
    
            <div className="msg-text">{msg?.message}</div>
            </div>
        </div>
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const decoded = jwtDecode(auth?.accessToken);
        const personName = decoded?.UserInfo?.username;
        
        setMsg('');

        const prev = msgs;
        const newMsgs = [...prev,{role:"user", name: personName, message: msg}];
        //console.log("newMsgs",newMsgs);
        setMsgs(newMsgs);

        try {
            const response = await axiosPrivate.post(
              MSG_URL,
              JSON.stringify({
                "name": personName,
                "message": msg
              }),
              {
                headers: { 'Content-Type': 'application/json' },
                withCredentials:true
              },
            );
            console.log(JSON.stringify(response?.data));
            setMsgs([...newMsgs,{role:"agent", name: BOT_NAME, message: response?.data?.message}]);

        } catch (err) {
            console.error(err);
        }

    };


    const newThread = async (e) => {

        console.log("newThread");
        try {
            const response = await axiosPrivate.post(
              MSG_URL + "/thread",
              JSON.stringify({
                "action": "new thread"
              }),
              {
                headers: { 'Content-Type': 'application/json' },
                withCredentials:true
              },
            );
            console.log(JSON.stringify(response?.data));
            setMsgs(response?.data);
        } catch (err) {
            console.error(err);
        }
    };

  return (   
    <div className="msger-body">
    <section className="msger">

      <header className="msger-header">
        <div className="msger-header-title">
          <strong>Lateral Chat</strong>
          <button type="button" className="msger-send-btn" onClick={newThread}>New Thread</button>
        </div>
        <div className="msger-header-options">
          <span><i className="fa fa-cog"></i></span>
        </div>
      </header>
      <main className="msger-chat" id="msger-chat">
      {msgs?.length ? (
          <ul ref={div}>
            {msgs.map((msg, i) => (
                getMessage(msg, i)
            ))}
          </ul>
        ) : (
          <p>No messages to display</p>
        )}
      </main>
      <form className="msger-inputarea" onSubmit={handleSubmit}>
        <input
            className="msger-input"
            placeholder="Enter your message..."
            type="text"
            id="username"
            autoComplete="off"
            data-lpignore="true"
            onChange={(e) => setMsg(e.target.value)}
            value={msg || ''}
            required
          />
        <button type="submit" className="msger-send-btn">Send</button>
      </form>
    </section>
    </div>

  );
};

export default Chatbot;