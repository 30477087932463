import Modal from 'react-bootstrap/Modal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Form from 'react-bootstrap/Form';
import ConfirmDelete from './confirmDelete';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ClientModal = (props) => {
  const clientId = props.clientId;
  const client = props.clientObj;
  const show = props.show;
  const [showConfirm, setShowConfirm] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientURL, setClientURL] = useState('');
  const [clientTagLine, setClientLine] = useState('');
  const [clientStyles, setClientStyles] = useState(''); 
  const [approvedToolkits, setApprovedToolkits] = useState([]);
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [clientAPI, setClientAPI] = useState(uuid());
  const [heading, setHeading] = useState('Add New Client');
  const renderAfterCalled = useRef(false);
  const [clientModel, setClientModel] = useState('gpt-3.5-turbo-1106');
  const [clientTemp, setClientTemp] = useState('0.6');
  const [toolkits, setToolkits] = useState();


  function compareByIndex(a, b) {
    if (parseInt(a.index) < parseInt(b.index)) {
      return -1;
    }
    if (parseInt(a.index) > parseInt(b.index)) {
      return 1;
    }
    return 0;
  }



  useEffect(() => {
    setErrMsg('');
    setSuccess(false);
    if (client) {
      setClientName(client.clientname);
      setClientURL(client.domainurl);
      setClientLine(client.tagline);
      setClientAPI(client.apikey);
      setClientModel(client.gptmodel);
      setClientTemp(client.gpttemp);
      setClientStyles(client.styles);
      setApprovedToolkits(client.approvedToolkits || []);
      setAllowedOrigins(client.allowedOrigins || []);
      setHeading('Edit Client Details');
      //console.log(client);
    } else {
      setClientName('');
      setClientURL('');
      setClientLine('');
      setClientAPI('');
      setClientModel('gpt-3.5-turbo-1106');
      setClientTemp('0.6');
      setClientStyles('');
      setApprovedToolkits([]);
      setAllowedOrigins([]);
      setHeading('Add New Client');
    }

    const controller = new AbortController();
    const getToolkits = async () => {
      if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get('/toolkits', {
            signal: controller.signal,
          });
          //console.log(response.data);
          const sorted = response.data.sort(compareByIndex);
          setToolkits(sorted);
        } catch (err) {
          console.error(err);
        }
      }
    };
    getToolkits();

    renderAfterCalled.current = true;
  }, [client, axiosPrivate]);

  const handleSubmit = async (event) => {
    if (clientName) {
      const payload = JSON.stringify({
        clientid: clientId,
        clientName: clientName,
        domainURL: clientURL,
        tagline: clientTagLine,
        apikey: clientAPI,
        gptModel: clientModel,
        gptTemp: clientTemp,
        approvedToolkits,
        styles:clientStyles,
        allowedOrigins: allowedOrigins.toString().replace(/' '/g,'').split(','),
      });

      let url = '';
      if (heading === 'Add New Client') {
        url = '/clients/add/';
      } else {
        url = '/clients/edit/';
      }

      //console.log('payload', payload);
      try {
        await axiosPrivate.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // console.log(response?.data);
        setSuccess(true);
      } catch (err) {
        formatError(err);
      }
    }

    setValidated(true);
  };

  function formatError(err) {
    if (!err?.response) {
      console.error('No Server Response');
      setErrMsg('No Server Response');
    } else if (err.response?.status === 409) {
      console.error('Client Exists');
      setErrMsg('Client Exists');
    } else {
      setErrMsg('Save Failed, missing fields');
      console.error('Save Failed');
    }
  }

  const genKey = () => {
    console.log('Genrating key');
    setClientAPI(uuid());
  };

  const updateToolkit = (newVal, kit) => {
    const inputs = newVal
      ? approvedToolkits.concat(kit)
      : approvedToolkits.filter(function (item) {
          return item !== kit;
        });
    setApprovedToolkits(inputs);
  };

  const handleCloseDelete = () => {
    setShowConfirm(false);
    props.setShow(true);
  };

  const deleteClient = async () => {
    await axiosPrivate.delete('/clients', {
      data: { clientid: clientId },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    setShowConfirm(false);
    props.handleClose();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setShowConfirm(true);
    props.setShow(false);
  };

  //console.log('toolkits', toolkits);

  return (
    <div>
      <ConfirmDelete
        showConfirm={showConfirm}
        handleClose={handleCloseDelete}
        clientName={clientName}
        deleteClient={deleteClient}
      />

      <Modal show={show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Alert variant="success">
              <Alert.Heading>Success!</Alert.Heading>
              Client has been saved.
            </Alert>
          ) : (
            <p></p>
          )}
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container fluid>
              <Row>
                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formClientName">
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter name"
                      value={clientName || ''}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formClientDomain">
                    <Form.Label>Domain URL</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Domain URL"
                      value={clientURL || ''}
                      onChange={(e) => setClientURL(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group className="mb-3" controlId="formClientTagline">
                    <Form.Label>Tagline</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Client tagline"
                      value={clientTagLine || ''}
                      onChange={(e) => setClientLine(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="pe-2">
                  <Form.Group className="mb-3" controlId="formClientModel">
                    <Form.Label>GPT Model</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="gpt-3.5-turbo-1106"
                      value={clientModel || ''}
                      onChange={(e) => setClientModel(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formClientTemp">
                    <Form.Label>GPT Tempurature</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="0.6"
                      value={clientTemp || ''}
                      onChange={(e) => setClientTemp(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formClientTagline">
                    <Form.Label>API Key</Form.Label>
                  </Form.Group>
                  <InputGroup className="mb-3">
                    <Form.Control
                      required
                      placeholder="API key"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={clientAPI || ''}
                      onChange={(e) => setClientAPI(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => genKey()}
                    >
                      Generate
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group
                    className="mb-3 toolkits-row"
                    controlId="formClientTagline"
                  >
                    <Form.Label>Approved Toolkits</Form.Label>
                    <br className="clear" />
                    <Container>
                    <Row>
                    {toolkits?.map((kit, idx) => (
                     
                     <Col xs={6} key={idx}>
                        <Form.Check
                          type="switch"
                          checked={approvedToolkits?.includes(
                            kit.collection,
                          )}
                          onChange={(e) =>
                            updateToolkit(e.target.checked, kit.collection)
                          }
                        />
                        <span>{kit.name}</span>
                     </Col>
                    ))}
                    </Row>
                    </Container>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {' '}
                <Col xs={12}>
                  <Form.Group className="mb-3" controlId="formClientTemp">
                    <Form.Label>
                      Allowed origins for API key (Seperate with commas)
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      placeholder=""
                      value={allowedOrigins || ''}
                      onChange={(e) => setAllowedOrigins(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {heading === 'Add New Client' ? (
            <span>
              <Button variant="secondary" onClick={props.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Add Client
              </Button>
            </span>
          ) : (
            <span>
              <Button variant="secondary" onClick={props.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete Client
              </Button>
            </span>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientModal;
