import React, {useEffect} from "react";
import Header from "../components/Header";
import Dashboard from "../components/Dashboard";
import Footer from "../components/Footer";


export default function DashboardPage () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Dashboard";
     }, []);


    return (
        
        <div className="MainDiv">
            <Header />
            <Dashboard />
            <Footer />
        </div>
    
    );
}