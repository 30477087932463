import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import axios from '../api/axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const Embed = () => {
  const { type, id, apikey } = useParams();
  //const APIKEY = '3803ba9c-912b-4a6c-84a4-c8541a95c30c'; //lateralthinking apikey
  const [userInputs, setUserInputs] = useState([]);
  const [widgetName, setWidgetName] = useState();
  const [originalPrompt, setOriginalPrompt] = useState();
  const [widgetSubmit, setWidgetSubmit] = useState();
  const [widgetInputs, setWidgetInputs] = useState();
  const [validated, setValidated] = useState(false);
  const [landingText, setLandingText] = useState();
  const [aiResponse, setAiResponse] = useState('');
  const [youtube, setYoutube] = useState('');
  const [image, setImage] = useState('');
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const renderAfterCalled = useRef(false);
  const [styles, setStyles] = useState({ background: '#ffffff' });

  function compareByOrder(a, b) {
    if (parseInt(a.inputOrder) < parseInt(b.inputOrder)) {
      return -1;
    }
    if (parseInt(a.inputOrder) > parseInt(b.inputOrder)) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    async function getWidget() {
      const controller = new AbortController();
      try {
        const response = await axios.get(
          '/ai/' + type + '/' + id + '/' + apikey,
          {
            signal: controller.signal,
          },
        );

        const sorted = response.data.data.inputs.sort(compareByOrder);
        setWidgetName(response.data.data.name);
        setWidgetSubmit(response.data.data.submit);
        setWidgetInputs(sorted);
        setLandingText(response.data.data.landingText);
        setOriginalPrompt(response.data.data.prompt);
        setYoutube(response.data.data.youtube);
        setImage(response.data.data.image);
        setStyles(response.data.clientdata.styles);
        //console.log('sorted', response.data);
      } catch (err) {
        console.error(err);
      }
    }
    if (!renderAfterCalled.current) {
      getWidget();
    }
    renderAfterCalled.current = true;
  }, [id, type, apikey]);

  const updateField = (newVal, index, controlname) => {
    let inputs;
    if (newVal === '') setValidated(false);
    var ui = widgetInputs.map((input) => ({ field: input.name, value: '' }));
    newVal = newVal.replace(/[^a-zA-Z ]/g, '');
    inputs = ui.map((input, i) =>
      i === index
        ? { field: input.field, value: newVal }
        : { field: input.field, value: userInputs[i]?.value || '' },
    );
    setUserInputs(inputs);
  };
  const handleSubmit = async () => {
    var blnError = false;
    let prompt = originalPrompt;
    userInputs.forEach((input) => {
      const newVal = input.value.replace(/[^a-zA-Z ]/g, '');
      if (!input.value) blnError = true;
      // eslint-disable-next-line no-eval
      eval(
        `prompt = prompt.replaceAll('{${input.field}}','<b><u>${newVal}</u></b>');`,
      );
    });

    if (apikey === '3803ba9c-912b-4a6c-84a4-c8541a95c30c') {
      var myCustomData = { showButton: 'true' };
      var event = new CustomEvent('buttonEvent', { detail: myCustomData });
      window.parent.document.dispatchEvent(event);
    }

    if (!blnError && userInputs.length > 0) {
      document.getElementsByClassName('info')[0].style.visibility = 'visible';
      setErrMsg('');
      setSuccess(true);
      setAiResponse('sending ...');

      let payload = JSON.stringify({
        prompt,
      });

      let url = '/ai/' + type + '/' + id + '/' + apikey;

      try {
        const response = await axios.post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        //console.log(response?.data.result);
        // var myCustomData = { foo: 'bar' };
        // var event = new CustomEvent('myEvent', { detail: myCustomData });
        // window.parent.document.dispatchEvent(event);
        setSuccess(true);
        setAiResponse(response?.data.result);
      } catch (err) {
        if (!err?.response) {
          console.error('No Server Response');
          setErrMsg('No Server Response');
        } else if (err.response?.status === 409) {
          console.error('Client Exists');
          setErrMsg('Widget Exists');
        } else {
          setErrMsg('Save Failed');
          console.error('Save Failed');
        }
      }
    } else {
      setSuccess(false);
      setErrMsg('Missing form values.');
    }
    setValidated(true);
  };

  const getYoutube = () => {
    if (youtube) {
      return (
        <iframe
          className="youtube-iframe"
          width="100%"
          height="100%"
          src={'https://www.youtube.com/embed/' + youtube}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      );
    } else {
      if (image) {
        return (
          <Image src={image} className="widget-image rounded fluid"></Image>
        );
      } else {
        return <></>;
      }
    }
  };

  const getStyles = () => {
    let s = {};

    if (styles.background) {
      s.backgroundColor = styles.background;
    }

    if (styles.color) {
      s.color = styles.color;
    }

    if (styles.font) {
      s.fontFamily = styles.font;
    }
    return s;
  };

  const getSecondary = () => {
    let s = {};

    if (styles.secondarycolor) {
      s.color = styles.secondarycolor;
    }
    return s;
  };

  return (
    <div className="embed-window" style={getStyles()}>
      {!widgetName ? (
        <p>Loading ...</p>
      ) : (
        <>
          <h2>{widgetName}</h2>
          <Container fluid>
            <Row>
              <Col md={6}>
                <div
                  style={getSecondary()}
                  dangerouslySetInnerHTML={{ __html: landingText }}
                ></div>
              </Col>
              <Col md={6} className="pe-5 ps-5 text-center">
                {getYoutube()}
              </Col>
            </Row>
            <Row>
              <Col md={6}>

              {' '}
                {success ? (
                  <Alert variant="success">
                    <Alert.Heading>Success!</Alert.Heading>
                    See AI response below.
                  </Alert>
                ) : (
                  <></>
                )}
                <p
                  ref={errRef}
                  className={errMsg ? 'errmsg' : 'offscreen'}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <Form noValidate validated={validated} className="mt-5">
                  {widgetInputs?.map((input, x) => (
                    <Form.Group
                      className="mb-3"
                      controlId={'form' + input.name}
                      key={x}
                    >
                      <Form.Label>{input.label}</Form.Label>
                      {input.type === 'text' ? (
                        <Form.Control
                          required
                          type="text"
                          placeholder={input.placeholder}
                          onChange={(e) =>
                            updateField(e.target.value, x, input.name)
                          }
                        />
                      ) : input.type === 'selectarea' ? (
                        <Form.Control
                          required
                          as="textarea"
                          rows={4}
                          placeholder={input.placeholder}
                          onChange={(e) =>
                            updateField(e.target.value, x, input.name)
                          }
                        />
                      ) : (
                        <p>{input.type}</p>
                      )}
                    </Form.Group>
                  ))}

                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleSubmit}
                  >
                    {widgetSubmit}
                  </Button>

                  <fieldset className="prompt-response info">
                    <legend>AI Response</legend>
                    {aiResponse}
                  </fieldset>
                </Form>
              </Col>
            </Row>
          </Container>
          <div className="clearfix"></div>
        </>
      )}
    </div>
  );
};

export default Embed;
