import React, { useEffect} from "react";
import Header from "../../components/Header.js";
import Courses from "../../components/generators/coursename.js";
import Footer from "../../components/Footer.js";


export default function CourseName () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Course Name Generator";
     }, []);


    return (
        
        <div className="MainDiv">
            <Header />
            <Courses />
            <Footer />
        </div>
    
    );
}