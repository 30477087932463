import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const WidgetModal = (props) => {
  //   var array1 = ["cat", "sum","fun", "run"]
  //   var array2 = ["bat", "cat","dog","sun", "hut", "gut", "sum","fun", "run"];
  //   const intersection = array1.filter(element => array2.includes(element));
  //  console.log("intersection",intersection);

  const showWidget = props.showWidget;
  const clientId = props.clientId;
  const clientObj = props.clientObj;
  const axiosPrivate = useAxiosPrivate();
  const [tabkey, setTabKey] = useState('CourseCreation');
  const [widgets, setWidgets] = useState();
  const [userInputs, setUserInputs] = useState([]);
  const renderAfterCalled = useRef(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const errRef = useRef();
  const [toolkits, setToolkits] = useState();


  function compareByIndex(a, b) {
    if (parseInt(a.index) < parseInt(b.index)) {
      return -1;
    }
    if (parseInt(a.index) > parseInt(b.index)) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getToolkits = async () => {
        try {
          const response = await axiosPrivate.get('/toolkits', {
            signal: controller.signal,
          });
          const intersection = response.data.sort(compareByIndex).filter(element => clientObj.approvedToolkits.includes(element.collection));
          setToolkits(intersection);
        } catch (err) {
          console.error(err);
        }
    };
    if(clientObj){
      getToolkits();
    }
    
  }, [axiosPrivate,clientObj]);


  useEffect(() => {
    const controller = new AbortController();
    const getWidgets = async () => {
      //if (!renderAfterCalled.current) {
        try {
          const response = await axiosPrivate.get(`/widgets`, {
            signal: controller.signal,
          });
          
          setWidgets(response.data);
        } catch (err) {
          console.error(err);
        }
      //}
    };

    if (clientId && showWidget) {
      getWidgets();
      setUserInputs(props?.clientObj?.approvedWidgets);
      renderAfterCalled.current = true;
    }

  }, [axiosPrivate, props, clientId, showWidget]);

  const updateField = (newVal, widgetId) => {
    //console.log("newVal",newVal);
    const inputs = newVal
      ? userInputs.concat(widgetId)
      : userInputs.filter(function (item) {
          return item !== widgetId;
        });
    setUserInputs(inputs);
  };

  const handleSubmit = async (event) => {
    const payload = JSON.stringify({
      clientid: clientId,
      approvedWidgets: userInputs,
    });

    let url = '/clients/editwidgets/';

    //console.log('payload', payload);
    try {
      await axiosPrivate.post(url, payload, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      // console.log(response?.data);
      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        console.error('No Server Response');
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        console.error('Client Exists');
        setErrMsg('Client Exists');
      } else {
        setErrMsg('Save Failed, missing fields');
        console.error('Save Failed');
      }
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setErrMsg();
    props.handleClose();
  };

  return (
    <Modal show={showWidget} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Approved Widgets For {clientObj?.clientname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">
            <Alert.Heading>Success!</Alert.Heading>
            Client has been saved.
          </Alert>
        ) : (
          <p></p>
        )}
        <p
          ref={errRef}
          className={errMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          activeKey={tabkey}
          onSelect={(k) => setTabKey(k)}
          className="mb-3"
        >
          {toolkits?.map((object, idx) => (
            <Tab
              eventKey={object?.collection}
              title={object?.name}
              key={idx}
        
            >
              <h3>{object?.name}</h3>
              <hr className="mt-1" />
              <Table responsive striped bordered hover className="admin-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                  </tr>
                </thead>
                {widgets?.length ? (
                  <tbody>
                   
                    {widgets.map((widget, i) => (

                      widget?.type=== object?.collection ? (

                      <tr key={widget?.id}>
                        <td className="select-td">
                          <Form.Check // prettier-ignore
                            type="switch"
                            checked={userInputs?.indexOf(widget?.id) > -1}
                            onChange={(e) =>
                              updateField(e.target.checked, widget?.id)
                            }
                          />
                        </td>
                        <td className="flex">
                          {widget?.data?.name}
                        </td>
                      </tr>
                      ) : (<></>)

                      
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>Loading ...</td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Tab>
          ))}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WidgetModal;
