import React, { useEffect} from "react";
import Header from "../../components/Header.js";
import GroupTopic from "../../components/generators/grouptopic.js";
import Footer from "../../components/Footer.js";

export default function GroupTopics () {

    useEffect(() => {
        document.title = "Lateral Thinking AI - Group Topic Generator";
     }, []);


    return (
        
        <div className="MainDiv">
            <Header />
            <GroupTopic />
            <Footer />
        </div>
    
    );
}