import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Table from 'react-bootstrap/Table';
import { useNavigate, useLocation } from 'react-router-dom';
import WidgetModal from './modifyWidget';
import WidgeInputs from './modifyInput';
import WidgePreview from './previewWidget';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Widgets = () => {
  const [showWidget, setShowWidget] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [heading, setHeading] = useState('');
  const [widgetId, setWidgetId] = useState();
  const [widgetType, setWidgetType] = useState();
  const [widgetToolkit, setWidgetToolkit] = useState();
  const [key, setKey] = useState(0);
  const [tabkey, setTabKey] = useState('CourseCreation');
  const [widgets, setWidgets] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const renderAfterCalled = useRef(false);
  const [toolkits, setToolkits] = useState();

  function compareByPage(a, b) {
    if (parseInt(a.data.pageNumber) < parseInt(b.data.pageNumber)) {
      return -1;
    }
    if (parseInt(a.data.pageNumber) > parseInt(b.data.pageNumber)) {
      return 1;
    }
    return 0;
  }
  function compareByIndex(a, b) {
    if (parseInt(a.index) < parseInt(b.index)) {
      return -1;
    }
    if (parseInt(a.index) > parseInt(b.index)) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getToolkits = async () => {
        try {
          const response = await axiosPrivate.get('/toolkits', {
            signal: controller.signal,
          });
          console.log(response.data.sort(compareByIndex));
          setToolkits(response.data.sort(compareByIndex));
        } catch (err) {
          console.error(err);
        }
    };
    getToolkits();
  }, [axiosPrivate]);


  useEffect(() => {
    const controller = new AbortController();

    const getWidgets = async () => {
      //if (!renderAfterCalled.current) {
      try {
        const response = await axiosPrivate.get(`/widgets`, {
          signal: controller.signal,
        });
        //console.log('response.data', response.data);
        const sorted = response.data.sort(compareByPage);
        // type === 'CourseCreation'
        //   ? setWidgets(sorted)
        //   : setWidgetsM(sorted);
        setWidgets(sorted);
      } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
      // }
    };

    getWidgets();
    //renderAfterCalled.current = true;
  }, [axiosPrivate, location, navigate, key]);

  const handleEditClick = async (id, type) => {
    setHeading('Edit Widget Details');
    setWidgetId(id);
    setWidgetType(type);
    
    setShowWidget(true);

    //console.log("handleEditClick",showWidget);
  };
  const handleInputClick = async (id, type, name) => {
    setHeading(name + ' Inputs');
    setWidgetId(id);
    setWidgetType(type);
    setShowInput(true);
  };
  const handleClose = () => {
    console.log("handleClose");
    renderAfterCalled.current = false;
    setShowWidget(false);
    setShowPreview(false);
    setWidgetId('');
    setKey(key + 1);
  };

  const setUpdate = () => {
    renderAfterCalled.current = false;
    setKey(key + 1);
  };
  const handleCloseInput = () => {
    renderAfterCalled.current = false;
    setShowInput(false);
    setKey(key + 1);
  };

  const addNew = (type, name) => {
    //console.log('adding new:', type);
    setHeading('Add New Widget');
    setWidgetId();
    setWidgetToolkit(name);
    setWidgetType(type);
    setShowWidget(true);
  };

  function compareByOrder(a, b) {
    if (parseInt(a.inputOrder) < parseInt(b.inputOrder)) {
      return -1;
    }
    if (parseInt(a.inputOrder) > parseInt(b.inputOrder)) {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <WidgePreview
        showPreview={showPreview}
        handleClose={handleClose}
        heading={heading}
        widgetId={widgetId}
        widgetType={widgetType}
      />
      <WidgeInputs
        showInput={showInput}
        handleClose={handleCloseInput}
        heading={heading}
        widgetId={widgetId}
        widgetType={widgetType}
        setUpdate={setUpdate}
      />
      <WidgetModal
        showWidget={showWidget}
        handleClose={handleClose}
        setShowWidget={setShowWidget}
        heading={heading}
        widgetId={widgetId}
        widgetType={widgetType}
        widgetToolkit={widgetToolkit}
        setUpdate={setUpdate}
      />
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        activeKey={tabkey}
        onSelect={(k) => setTabKey(k)}
        className="mb-3"
      >
        {toolkits?.map((object, idx) => (

          <Tab eventKey={object?.collection} title={object?.name} key={idx} onClick={() =>(setWidgetType(object?.collection))}>
            <h3>
              {object?.name}
              <Button
                className="float-right"
                variant="success"
                size="sm"
                onClick={() => addNew(object?.collection, object?.name)}
              >
                Add New
              </Button>
            </h3>

            <hr className="mt-1" />
            <Table responsive striped bordered hover className="admin-table">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Name</th>
                  <th>Inputs</th>
                  <th>Prompt</th>
                  <th>Submit Button</th>
                  <th></th>
                </tr>
              </thead>
              {widgets?.length ? (
                <tbody>
                  {widgets.map((widget, i) => (
                    widget?.type=== object?.collection ? (
                    <tr key={widget?.id}>
                      <td>{widget?.data?.pageNumber}</td>
                      <td className="td">{widget?.data?.name}</td>
                      <td className="td">
                        {widget?.data?.inputs.sort(compareByOrder).map((input, x) => (
                          <span key={x}>
                            {input.name}
                            {x + 1 < widget?.data?.inputs.length ? ', ' : ''}
                          </span>
                        ))}
                      </td>
                      <td>
                        <div className="flex">{widget?.data?.prompt}</div>
                      </td>
                      <td className="td">{widget?.data?.submit}</td>
                      <td className="controls text-nowrap">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() =>
                            handleEditClick(widget?.id, widget?.type)
                          }
                        >
                          Details
                        </Button>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() =>
                            handleInputClick(
                              widget?.id,
                              widget?.type,
                              widget?.data?.name,
                            )
                          }
                        >
                          Inputs
                        </Button>
                          <br />
                        <Button
                          variant="outline-danger"
                          className="live-preview-btn"
                          size="sm"
                          onClick={() =>{setWidgetId(widget?.id);setShowPreview(true);}}
                        >
                          Live Preview
                        </Button>


                      </td>
                    </tr>

                    ) : (<></>)
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Loading ...</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default Widgets;
