import { useState } from 'react';
import Users from './users/Users';
import Button from 'react-bootstrap/Button';


const User = () => {

  const [addUser, setAdd] = useState(false);
  const addNew = (e) => {
    setAdd(true);
    //console.log("User addNew:",addUser)
  };


  return (
    <div className="mt-5">
        <h1>User Management{' '}<Button variant="success" size="sm" onClick={() => addNew()}>
            Add New
          </Button></h1>
        <br />
        <Users addUser={addUser} setAdd={setAdd} />
        <br />
    </div>
  );
};

export default User;
